export default {
    props: ['card_title', 'image'],
    computed: {
        serviceTexts() {
            return [
                this.$i18n.t('serviceText1'),
                this.$i18n.t('serviceText2'),
                this.$i18n.t('serviceText3'),
                this.$i18n.t('serviceText4'),
                this.$i18n.t('serviceText5'),
            ]
        },
        sleepArrangementTexts() {
            return [
                this.$i18n.t('sleepArrangement1'),
                this.$i18n.t('sleepArrangement2'),
                this.$i18n.t('sleepArrangement3'),
            ]
        },
        amneties() {
            return [
                this.$i18n.t('Electric stove'),
                this.$i18n.t('Electric oven'),
                this.$i18n.t('Kettle'),
                this.$i18n.t('Microwave'),
                this.$i18n.t('Toaster'),
                this.$i18n.t('French press'),
                this.$i18n.t('Thermos'),
                this.$i18n.t('Plates, mugs, knives and forks at least 8 sets'),
                this.$i18n.t('Cooking-ware (pots, pans, bowls, platter, jug etc'),
                this.$i18n.t('Salt'),
            ]
        },
        saunaTexts() {
            return [
                this.$i18n.t('saunaText1'),
                this.$i18n.t('saunaText2'),
            ]
        },
        pondTexts() {
            return [
                this.$i18n.t('pondText1'),
                this.$i18n.t('pondText2'),
            ]
        }
    }
}
